import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			showDebug: false,
			adPosConfig: {
				all_m: [1, 2],
			},
			ads: {
				home_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-646', 'data-ad-slot': '1437846030', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-646', 'data-ad-slot': '9124764368', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-646', 'data-ad-slot': '7811682692', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-646', 'data-ad-slot': '8119638609', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },

				all: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-646', 'data-ad-slot': '2898331000', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-646', 'data-ad-slot': '3549838207', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],
			},

			adsensConfig: {},
			domainConfig: null,
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			setAdsense(state, adsenseConfig) {
				state.adsensConfig = adsenseConfig
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
			domainConfig: (state) => state.domainConfig,
		},
	})
}
